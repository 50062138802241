<template>
  <v-container fluid>
    <v-row justify="center">

<v-col cols="12" md="6">
        <v-expansion-panels popout>
        <v-expansion-panel
          v-for="(message, i) in messages"
          :key="i"
          hide-actions
        >
          <v-expansion-panel-header>
            <v-row
              align="center"
              class="spacer"
              no-gutters
            >
              <v-col
                cols="4"
                sm="2"
                md="1"
              >
                <v-avatar
                  size="36px"
                >
                  <img
                    v-if="message.avatar"
                    alt="Avatar"
                    src="https://avatars0.githubusercontent.com/u/9064066?v=4&s=460"
                  >
                  <v-icon
                    v-else
                    :color="message.color"
                    v-text="message.icon"
                  ></v-icon>
                </v-avatar>
              </v-col>

              <v-col
                sm="5"
                md="7"
              >
                <strong class="text-h6 text-sm-h5" v-html="message.name"></strong>
                <span
                  v-if="message.total"
                  class="grey--text"
                >
                  &nbsp;({{ message.total }})
                </span>
              </v-col>

             
              
            </v-row>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-divider></v-divider>
            <v-card-text v-text="lorem"></v-card-text>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
</v-col>
<v-col cols="12" md="6">
        <v-expansion-panels popout>
        <v-expansion-panel
          v-for="(message, i) in messagesss"
          :key="i"
          hide-actions
        >
          <v-expansion-panel-header>
            <v-row
              align="center"
              class="spacer"
              no-gutters
            >
              <v-col
                cols="4"
                sm="2"
                md="1"
              >
                <v-avatar
                  size="36px"
                >
                  <img
                    v-if="message.avatar"
                    alt="Avatar"
                    src="https://avatars0.githubusercontent.com/u/9064066?v=4&s=460"
                  >
                  <v-icon
                    v-else
                    :color="message.color"
                    v-text="message.icon"
                  ></v-icon>
                </v-avatar>
              </v-col>

              <v-col
                sm="5"
                md="7"
              >
                <strong class="text-h6 text-sm-h5" v-html="message.name"></strong>
                <span
                  v-if="message.total"
                  class="grey--text"
                >
                  &nbsp;({{ message.total }})
                </span>
              </v-col>

             

              
            </v-row>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-divider></v-divider>
            <v-card-text v-text="lorem"></v-card-text>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
</v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    data: () => ({
      messages: [
        // {
        //   avatar: 'https://avatars0.githubusercontent.com/u/9064066?v=4&s=460',
        //   name: 'John Leider',
        //   title: 'Welcome to Vuetify!',
        // },
        {
          color: 'primary',
          icon: 'mdi-account-question',
          name: 'What is Health Insurance?',
        //   title: 'click here to open',
        },
        {
          color: 'primary',
          icon: 'mdi-account-question',
          name: 'Why do I need health Insurance?',
        //   title: 'click here to open',
        },
      
        // {
        //   color: 'teal',
        //   icon: 'mdi-tag',
        //   name: 'Promos',
        //   new: 2,
        //   total: 4,
        //   title: 'Shop your way',
        //   exceprt: 'New deals available, Join Today',
        // },
      ],
      messagesss:[
          {
          color: 'primary',
          icon: 'mdi-account-question',
          name: 'What is Health Insurance?',
        //   title: 'click here to open',
        },
        {
          color: 'primary',
          icon: 'mdi-account-question',
          name: 'Why do I need health Insurance?',
        //   title: 'click here to open',
        },
      ],
      lorem: 'Lorem ipsum dolor sit amet, at aliquam vivendum vel, everti delicatissimi cu eos. Dico iuvaret debitis mel an, et cum zril menandri. Eum in consul legimus accusam. Ea dico abhorreant duo, quo illum minimum incorrupte no, nostro voluptaria sea eu. Suas eligendi ius at, at nemore equidem est. Sed in error hendrerit, in consul constituam cum.',
    }),
  }
</script>

<style>

</style>