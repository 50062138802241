<template>
  <v-app>
  <navbar/>
  <layouthome/>
  <foot/>
  </v-app>
</template>

<script>
import Faq2 from './components/home/faq2.vue';
import Foot from './components/home/foot.vue';
import Layouthome from './components/home/layouthome.vue';
import Navbar from './components/Navbar.vue';

export default {
  name: 'App',

  components: {
    Navbar,
    Layouthome,
    Faq2,
    Foot,
  },

  data: () => ({
    //
  }),
};
</script>
