<template>
  <section id="toppage">
      <v-carousel 
        :cycle="cycle"
        height="100vh"
        :show-arrows="false"
        hide-delimiters
      >
       
          <v-carousel-item
            v-for="(item,i) in items"
            :key="i"
            :src = "item.src"
          >
          <v-layout fill-height>
                <v-row >
                    <v-col cols=12 sm="6">
                        <v-container  fill-height align-center style="background: linear-gradient(90deg, rgba(35,55,116,0.6615021008403361) 30%, rgba(35,55,116,0.4290091036414566) 80%, rgba(35,55,116,0.01724439775910369) 100%);">
                            <v-row align="center" justify="center">
                                <v-col cols=10 md=8 sm=10 >
                                    <h1 class="display-3 text-sm-h2 text-md-h2 font-weight-bold mb-6 white--text">Healthcare</h1>
              <h1 class="display-1 text-sm-h3 text-md-h3 font-weight-light white--text transition-swing">
                That's There For You<br>
                When You Need It Most
              </h1>
  <v-row justify="start">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
           rounded
                large
          v-bind="attrs"
          v-on="on"
          @click="cycle = false"
          class="mt-8 font-weight-bold text-h5 "
        >
          Get Quote !
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">Get Quote Now</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="fname"
                  label="Full Name*"
                  :rules="fnamerule"
                  required
                ></v-text-field>
              </v-col>
              <!-- <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                v-model="mname"
                  label="Middle Name"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                v-model="lname"
                  label="Legal last name*"
                  :rules="lnamerule"
                  required
                ></v-text-field>
              </v-col> -->
              <v-col cols="12">
                <v-text-field
                v-model="email"
                  label="Email*"
                  :rules="emailrule"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                v-model="number"
                  label="Mobile Number"
                  prefix="+971"
                  :rules="mobrule"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
              >
              <div>Insurance For *</div>
                 <v-radio-group
      v-model="age"
      row
    >
      <v-radio
        label="Self & Family"
        value="self_and_family"
      ></v-radio>
      <v-radio
        label="Domestic Worker"
        value="domestic_worker"
      ></v-radio>
       <v-radio
        label="For Company"
        value="for_company"
      ></v-radio>
    </v-radio-group>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="cancelbtn"
          >
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="submitbtn"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
                                </v-col>
                            </v-row>
                         </v-container>
                    </v-col>
                    <v-col class="hidden-sm-and-down" cols=12 sm="6" align-self="end">
                       <v-row align="start" justify="end">
                           <h1 class="display-1 font-weight-light white--text mb-10 mr-5" style="opacity:0.7;">
                <a class="white--text" @click="$vuetify.goTo('#process')">SCROLL DOWN</a>
              </h1>
                       </v-row>
                    </v-col>
                   
                </v-row>

          
    </v-layout>
          </v-carousel-item>
         
          </v-carousel>
           
  </section>
</template>


<script>
import emailjs from 'emailjs-com';

export default {
name:"topPage",
data:()=>({
  dialog: false,
  cycle: true,
  fname: '',
  fnamerule: [
        v => !!v || 'Firest Name is required',
      ],
  mname: '',
  lname: '',
  lnamerule: [
        v => !!v || 'last Name is required',
      ],
  number: '',
  mobrule: [
        v => !!v || 'Mobile Number is required',
      ],
  email: '',
  emailrule: [
        v => !!v || 'Email is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
  age: null,
  agerule: [
        v => !!v || 'Age is required',
      ],
    items: [
        {
            src: require('@/assets/top.jpg')
        },
        {
            src: require('@/assets/top1.jpg')
        },
        {
            src: require('@/assets/top2.jpg')
        },{
            src: require('@/assets/top3.jpg')
        }
    ],
}),

methods: {
  cancelbtn () {
this.fname=''
this.mname=''
this.lname=''
this.email=''
this.number=''
this.age=null
this.dialog=false
this.cycle=true
  },

  submitbtn (e) {
try {
  emailjs.init("user_OPz7kWF2wd327MeaGdXVp")
   emailjs.send("service_xz7n1d4","template_yyg8r9r", {
          fname: this.fname,
          mname: this.mname,
          lname: this.lname,
          email: this.email,
          number: this.number,
          age: this.age
        })
  
} catch (error) {
  console.log({error})
}
this.fname=''
this.mname=''
this.lname=''
this.email=''
this.number=''
this.age=null
this.dialog=false
this.cycle=true
  },
},
};


</script>

<style>

</style>