<template>
  <v-footer dark padless>
    <v-card flat tile class="primary white--text text-center">
      <v-card-text>
        <v-btn
          v-for="(icon, i) in icons"
          :key="i"
          class="mx-4 white--text text-h6"
          :href="icon.link"
          target="_blank"
          icon
        >
          <v-icon size="24px">{{ icon.text }}</v-icon>
        </v-btn>
      </v-card-text>

      <v-card-text class="white--text text-h6 py-0">
        That's There For You When You Need It Most.
      </v-card-text>
      <v-card-text class="white--text text-h5 pt-0">
        <v-icon
      color="white darken-2"
    >
      mdi-copyright
    </v-icon> 
         <strong>  VOWFIRST</strong> | {{ new Date().getFullYear() }} - All Right Reserved.
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
    data: () => ({
    icons: [
      {
        text: "mdi-facebook",
        link: "",
      },
      {
        text: "mdi-twitter",
        link: "",
      },
      {
        text: "mdi-linkedin",
        link: "",
      },
      {
        text: "mdi-instagram",
        link: "https://instagram.com/",
      },
    ],
  }),
}
</script>

<style>
.v-card {
  width: 100%;
}
</style>