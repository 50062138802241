<template>
    <div>
        <v-main class="pt-0">
        <toppage></toppage>
        <working></working>
        <insurance></insurance>
        <benefits></benefits>
        <!-- <insurancetype></insurancetype> -->
        <!-- <customer></customer> -->
        <!-- <counter/> -->
        <faq></faq>
        </v-main>
    </div>
</template>

<style>
    .v-main {
  background-image: url("~@/assets/bgMain.png");
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}
</style>

<script>
import Toppage from './toppage.vue';
import working from './workingprocess.vue';
import insurance from './insuranceinfo.vue'
import Benefits from './benefits.vue';
// import insurancetype from './insurancetype.vue'
// import Customer from './customer.vue';
import faq from './faq.vue';
// import counter from './counter.vue';

export default {
    
    name:"HomeLayout",
    components: {
        Toppage,
        working,
        insurance,
        Benefits,
        // insurancetype,
        // Customer,
        faq,
        // counter
        
    },
};
</script>