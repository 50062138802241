<template>
  <section id="faq">
        <v-row  align="center" justify="center">
             <v-col cols="10" class="text-center pt-5">
             <!-- <h1 class="display-2 font-weight-bold mt-5 pb-12" style="color:#233774">
                FAQ's
              </h1>
              <div class="flex row mt-12">
              
                <faqq/>
              </div> -->
                 <h1 class="display-1 text-sm-h4 text-md-h3 font-weight-bold mt-12 pt-10 pb-10" style="color:#233774">
                Join with 10,000 <span style="color:#ED0F7E">VOW</span>FIRST People
              </h1>
               <v-row justify="center" class="my-10" >
                   
                     <v-col
          cols="10" sm="3"
        >
          <v-text-field
          v-model="name"
            label="Your Name"
            placeholder="Enter your name"
            filled
            rounded
            dense
          ></v-text-field>
        </v-col>
         <v-col
          cols="10" sm="3"
        >
          <v-text-field
          v-model="number"
            label="Mobile Number"
            placeholder="Enter your mobile number"
            filled
            rounded
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="10" sm="4">
             <v-btn
      rounded
      color="primary"
      class="pa-6 text-h6"
      @click="submit"
    >
      REQUEST CALL BACK
    </v-btn>
        </v-col>
               </v-row>

         </v-col>
        </v-row>
  </section>
</template>

<script>
import faqq from './faq2.vue';
import emailjs from 'emailjs-com';

export default {
  components:{
    faqq
  },
    data: () => ({
      reveal: false,
      q1: false,
      q2: false,
      q3: false,
      name: '',
      number: '',
    }),

    methods: {
      submit () {
try {
  emailjs.init("user_OPz7kWF2wd327MeaGdXVp")
   emailjs.send("service_xz7n1d4","template_b2m9whq", {
          name: this.name,
          number: this.number
        })
  
} catch (error) {
  console.log({error})
}
this.name=''
this.number=''

      }
    },
}
</script>

<style>
@media screen and (min-width: 600px) {
#faq{
  width: 100%;
}}
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>