<template>
<v-app-bar
  app
  color="white"
  flat
  light
  elevation="6"
  elevate-on-scroll
  fixed
>
<v-img
class="ml-10"
:src="require('@/assets/logo.png')"
max-height="70"
max-width="150"
alt="VOWFIRST"
contain
></v-img>
<v-spacer></v-spacer>
<v-row align="center" justify="center" class="hidden-sm-and-down">
  <v-list class="d-flex align-center">
    <v-list-item v-for="(menu,index) in menus" :key="index" :to="menu.route">
      <v-list-item-title class="pl-5 ">{{menu.title}}</v-list-item-title>
    </v-list-item>
</v-list>
<v-spacer></v-spacer>
<v-btn rounded color="primary" class="mr-10">
  Login to HRMS
</v-btn>
<v-spacer></v-spacer>
</v-row>
 <v-app-bar-nav-icon class="mr-12 hidden-md-and-up" light @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
  <v-dialog
      v-model="drawer"
      fullscreen
      hide-overlay
      transition="dialog-top-transition"
    >
    
      <v-list
        nav
        dense
      >
  <v-btn
            icon
            light
            @click="drawer = !drawer"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        <v-list-item-group
          v-model="group"
          active-class="primary text--accent-4"
        >
          <v-list-item v-for="(menu,index) in menus" :key="index">
      <v-list-item-title class="pl-5 ">{{menu.title}}</v-list-item-title>
    </v-list-item>
        </v-list-item-group>
        <v-btn rounded color="primary" class="mr-10" @click="drawer = !drawer">
  Login to HRMS
</v-btn>
      </v-list>
    </v-dialog>
</v-app-bar>

</template>

<script>
  export default {
    name: 'Nav',
    data: ()=>({
      menus: [
        {title:'HOME', route:'home'},
        {title:'HRMS', route:'hrms'},
        {title:'ABOUT', route:'about'},
        {title:'CONTACT US', route:'contact_us'},
      ],
      drawer: false,
      group: null,
    }),
      watch: {
      group (index) {
        this.drawer = false
        console.log(this.menus[index].title)
      },
    },

  }
</script>

<style>
@media screen and (min-width: 600px) {
.main {
  height: 100vh;
  width: 100%;

}}
</style>