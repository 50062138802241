<template>
  <div class="home">
    <layouthome/>
  </div>
</template>

<script>
import Layouthome from '../components/home/layouthome.vue'
// @ is an alias to /src

export default {
  name: 'home',
  components: {
    Layouthome
  }
}
</script>
