<template>
    <section id="benefit">
        <div class="text-center">
         
              <v-row class="hidden-sm-and-down" align="center" justify="center">
                <v-col cols=11>
                    <v-card shaped>
                    <v-col cols="12" class="text-center pt-2">
                        <h1 class="display-1 text-sm-h4 text-md-h3 font-weight-bold mt-2 pb-4 animate__animated animate__backInLeft" style="color:#233774">
               <span style="color:#ED0F7E">Vow</span>First USP
              </h1>
              <p class="text-h6 px-13 pb-2">
                With VowFirst, you are not just selecting a broker for one-time purchase. In fact, you are selecting a partner who is available for you anytime, anywhere.
              </p>

                <v-row align="center" justify="space-around">
                   <v-card
                    class="d-flex justify-space-around mb-4 mt-5"
                    flat
                    tile
                   >
                <v-col cols="3" v-for="(item,i) in items"
                :key="i">
                  <v-card
               
                class="mx-2 pt-5"
                
                shaped
                >
                    <v-img
                    class="mt-5 mx-5"
              :src="item.img"
              height="150px"
              contain
            >
             
            </v-img>
             <v-card-title class="text-h5 justify-center py-5 mt-10">{{item.head}}</v-card-title>
               <v-card-subtitle class="text-left justify-center py-10">{{item.sub}}</v-card-subtitle>
                </v-card>
                </v-col>


                   </v-card>
                </v-row>

                    </v-col>
        </v-card>

            </v-col>
          </v-row>
           <v-row class="hidden-md-and-up" align="center" justify="center">
                <v-col cols=11>
                    <v-card >
                    <v-col cols="12" class="text-center pt-5">
                        <h1 class="text-h4 text-md-h3 font-weight-bold mt-5 pb-5 animate__animated animate__backInLeft" style="color:#233774">
               <span style="color:#ED0F7E">Vow</span>first USPs
              </h1>
              <p class="text-md-h6 px-10 pb-2">
                With VowFirst, you are not just selecting a broker for one-time purchase. In fact, you are selecting a partner who is available for you anytime, anywhere.
              </p>

                <v-row align="center" justify="space-around">
                   <v-card
                    class="d-flex justify-space-around mb-1 mx-5"
                    flat
                    tile
                   >
                    <v-carousel cycle v-model="model" height="450px" hide-delimiters :show-arrows="false">
                  <v-carousel-item
                  v-for="(item,i) in items"
                    :key="i"
                  >
                <v-card
                class="mx-5"
                height="420px"
                width="90%"
                shaped
                >
                    <v-img
                    class="mt-5 mx-5"
              :src="item.img"
              height="175px"
              contain
            >
             
            </v-img>
             <v-card-title class="text-h5">{{item.head}}</v-card-title>
               <v-card-subtitle class="text-left">{{item.sub}}</v-card-subtitle>
                </v-card>
                
                </v-carousel-item>
               </v-carousel>

                   </v-card>
                </v-row>

                    </v-col>
        </v-card>

            </v-col>
          </v-row>
         
        </div>
    </section>
</template>

<script>
export default {
    name:"Benefits",
    data:()=>({
   model: 0,
   items:[
        {
          head:"Insurance Concierge",
          sub: "We understand that emergencies can arise anytime. And that is why we are available to support you during those testing hours with any insurance-related service.",
          img: require('@/assets/usp1.svg'),
        },
          {
          head:"Healthcare at your doorstep",
          sub: "With VowFirst, you not only get an insurance plan, but you get healthcare on-demand with our teleconsultation and pharmacy delivery service",
          img: require('@/assets/usp2.svg'),
        },
          {
          head:"Exclusive Plans",
          sub: "With our vast experience in medical insurance, we understand what our customers need, and so we have VowFirst exclusive plans to provide maximum features in a single plan",
          img: require('@/assets/usp3.svg'),
        },
      ],
 }),
}
</script>

<style>
@media screen and (min-width: 600px) {
#benefit{
  
  width: 100%;
}}
</style>