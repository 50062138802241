<template>
  <section id="process"> 
    <v-layout fill-height>
      <v-col cols=12>
        <v-row align="center" justify="center">
        <h1 class="display-1 text-sm-h4 text-md-h3 text-center font-weight-bold mt-2  pb-12" style="color:#233774">
                OUR WORKING PROCESS
              </h1>
        </v-row>
         
        <div class="hidden-sm-and-down">
          <v-carousel  v-model="model"
        cycle
        height="57vh"
        :show-arrows="false"
        hide-delimiters
        >
          <v-carousel-item 
            v-for="(item,i) in items"
            :key="i"
          >
           <v-row align="center" justify="center">
            <v-col
         
          cols="10" md="10"
        >
          <v-card
          elevation="10"
          shaped
          height=55vh
          >
            <v-row align="center" justify="center" >
              <v-col sm="6" md="8">
                <div class="ml-12">
                <v-card-title
                  class="text-h3 text-md-h4" style="color:#ED0F7E;">{{item.head}}</v-card-title>

                <v-card-subtitle class="text-h5 pt-5">{{item.sub}}</v-card-subtitle>

                <v-card-actions>
                  <v-btn
                    class="ml-2 pa-5 mt-4 text-h6"
                    color=primary
                    rounded
                  >
                    Let's Start
                  </v-btn>
                </v-card-actions>
              </div>
              </v-col>

              <v-col sm="6" md="4">
                <v-avatar
                class="ma-10"
                size="35vh"
                rounded
                align-center
              >
                <v-img contain :src="item.img"></v-img>
              </v-avatar>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
          </v-row>
       
          </v-carousel-item>
        </v-carousel>
        </div>
        <div class="hidden-md-and-up">
          <v-carousel  v-model="model"
        cycle
        :show-arrows="false"
        hide-delimiters
        height="70vh"
        >
          <v-carousel-item 
            v-for="(item,i) in items"
            :key="i"
          >
           <v-row align="center" justify="center">
             
            <v-col
         
          cols="10" md="10"
        >
          <v-card
          elevation="10"
          shaped
          >
            <v-row align="center" justify="center" >
              <v-col cols="10">
                <v-avatar
                class="mt-5"
                size="20vh"
                rounded
                align-center
              >
                <v-img contain :src="item.img"></v-img>
              </v-avatar>
              </v-col>
              <v-col cols="12">
                <div class="mx-2">
                <v-card-title
                  class="text-h5 text-sm-h4" style="color:#ED0F7E;">{{item.head}}</v-card-title>

                <v-card-subtitle class="text-body-1 text-sm-h6 pt-1">{{item.sub}}</v-card-subtitle>

                <v-card-actions>
                  <v-btn
                    class="ml-2 pa-5 mt-1 text-subtitle-2 text-sm-h6"
                    color=primary
                    rounded
                  >
                    Let's Start
                  </v-btn>
                </v-card-actions>
              </div>
              </v-col>

              </v-row>
          </v-card>
        </v-col>
          </v-row>
       
          </v-carousel-item>
        </v-carousel>
        </div>
      
 
      <v-row class="" align="center" justify="center">
         <v-col cols="9">
          <v-carousel v-model="model"
        cycle
        :show-arrows="false"
        hide-delimiters
        height="80"
        >
          <v-carousel-item 
            v-for="(item,i) in items"
            contain
            :key="i"
            :src="item.stepImg"
            transition="fade-transition"
          >
          
          </v-carousel-item>
        </v-carousel>
       </v-col>
      </v-row>
       
    </v-col>
    </v-layout>
  </section>
</template>

<script>
export default {
    name:"working",
    data:()=>({
      model:0,
      items:[
        {
          head:"Share your requirements",
          sub: "Give details for the insured person to show relevant quotes",
          img: require('@/assets/working1.svg'),
          stepImg: require('@/assets/w1.svg')
        },
          {
          head:"View and compare different plans",
          sub: "Based on the information shared, view different available plans with complete details. Compare plans side-by-side for better decisioning.",
          img: require('@/assets/working2.svg'),
          stepImg: require('@/assets/w2.svg')
        },
          {
          head:"Choose the right plan",
          sub: "Select a plan that meets your requirements and leave the rest to us!",
          img: require('@/assets/working3.svg'),
          stepImg: require('@/assets/w3.svg')
        },
      ],
    }),

}
</script>

<style>
@media screen and (min-width: 600px) {
  #process{
  height: 93vh;
  width: 100%;
}
}

</style>