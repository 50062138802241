<template>
  <section id="insurance">
      <div
      class="d-flex align-stretch ma-1 mt-2"
      height=""
      white="100%"
    >
    <v-col align-self="center">
        <v-row align="center" justify="center">
           <v-col cols="3" class="hidden-sm-and-down">
            
                <v-card  height="80vh" shaped>
                   <v-row style="height:80vh" class="px-2" align="center" justify="center">
                      <v-col v-for="(item,i) in items" :key="i" cols="12" class="text-center pt-10">
                        <v-card
                            v-if="model==i"
                            shaped
                            elevation="8"
                            @click="[model=i]"
                            color="secondary"
                            class="py-2"
                        >
                        <v-row align="center" justify="center">
                          
                               <v-col cols="3">
                                   <v-img
                                    :src=item.colimg
                                    height="80"
                                    contain
                                      />
                               </v-col>
                                <v-col cols="8">
                                   <h1 class="font-weight-regular white--text">{{items[i].head}}</h1>
                                </v-col>
                        </v-row>
                 
                        </v-card>
                         <v-card
                            v-else
                            shaped
                            elevation="8"
                            @click="[model=i]"
                            class="py-2"
                        >
                                 <v-row align="center" justify="center">
                          
                               <v-col cols="3">
                                   <v-img
                                    :src=item.colimg
                                    height="80"
                                    contain
                                      />
                               </v-col>
                                <v-col cols="8">
                                   <h1 class="font-weight-regular">{{items[i].head}}</h1>
                                </v-col>
                        </v-row>
                 
                        </v-card>

                    </v-col>
                   </v-row>
        </v-card>
           </v-col>
           <v-col cols="8" class="hidden-sm-and-down">
                <v-card  height="80vh" shaped>
                    <v-col cols="12" class="text-center pt-2">
                        <h1 class="display-2 font-weight-bold mt-3 pb-12" style="color:#233774">
                HRMS / INSURANCE Features
              </h1>
              <v-carousel v-model="model" height="530px" hide-delimiters :show-arrows="false">
                  <v-carousel-item
                  v-for="(item,i) in items"
                    :key="i"
                  >
               <v-row align="center" justify="center">

                  <v-col cols="6">
                      <h1 class="display-1 text-sm-h3 font-weight-bold mt-1 pb-1" style="color:#ED0F7E">
                {{item.head}}
              </h1>
                    <h4 class=" px-1 pt-5">{{item.sub}}
              </h4>
                  </v-col>


                 <v-col cols="5" class="mr-2">
                  
                     
                    <v-img
              :src="item.img"
              height="55vh"
              contain
            >
              
            </v-img>
                
                 </v-col>

               </v-row>
  </v-carousel-item>
               </v-carousel>
                    </v-col>
        </v-card>
           </v-col>
           <v-col cols="11" class="hidden-md-and-up">
                <v-card  height="" shaped>
                    <v-col cols="12" class="text-center pt-5">
                        <h1 class="display-1 text-sm-h3 font-weight-bold mt-1 pb-1" style="color:#233774">
                HRMS / INSURANCE Features
              </h1>
             
               <v-row align="center" justify="center">

              


                 <v-col cols="12" class="mr-2">
                   <v-carousel cycle height="700px" hide-delimiters :show-arrows="false">
                  <v-carousel-item
                  v-for="(item,i) in items"
                    :key="i"
                    transition="fade-transition"
                  >
                         <v-col cols="12">
                     <h1 class="display-1 text-sm-h3 font-weight-bold mt-1 pb-1" style="color:#ED0F7E">
                {{item.head}}
              </h1>
                    <h3 class="subtitle-1 px-1 pb-3">{{item.sub}} </h3>
                  </v-col>
                    <v-img
              :src="item.img"
              height="310px"
              contain
            >
              <!-- <v-card-title class="display-2">{{item.head}}</v-card-title>
               <v-card-title>{{item.sub}}</v-card-title> -->
            </v-img>
                  </v-carousel-item>
               </v-carousel>
                 </v-col>

               </v-row>

                    </v-col>
        </v-card>
           </v-col>
        </v-row>
    </v-col>
    </div>
  </section>
</template>

<script>
export default {
 name:"insuranceinfo",
 data:()=>({
   model: 0,
   items:[
        {
          head:"Search within your healthcare network",
          sub: "Get details on your covered hospitals, clinics and pharmacies right at your fingertips. What more - no tricky excels, no long lists; locate the center closest to you over an easy-to-use map-based interface.",
          img: require('@/assets/type3.png'),
          colimg: require('@/assets/1col.svg')
        },
          {
          head:"Raise claims right from your phone",
          sub: "Submit your claims and reimbursements online and leave the paperwork to us!",
          img: require('@/assets/type3.png'),
          colimg: require('@/assets/2col.svg')
        },
          {
          head:"Access your policy online",
          sub: "Get all your policy information, including digital card, benefits details, policy documents at one place!",
          img: require('@/assets/type3.png'),
          colimg: require('@/assets/3col.svg')
        },
        // {
        //   head:"Insurance Feature 4",
        //   sub: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the ",
        //   img: require('@/assets/type1.png'),
        // },
      ],
 }),
}
</script>

<style>
@media screen and (min-width: 600px) {
#insurance{
  height: 93vh;
  width: 100%;
}}
</style>